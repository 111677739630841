<template>
    <div style="background:black;">
      <div class="header">
        <div class="logo_img" @click="logoClick">
          <img id="header-img" src="../assets/gord.png" />
        </div>
        <div id="menu_index">
          <!-- web端菜单导航 -->
          <el-menu
            v-if="seen"
            :default-active="activeIndex"
            class="el-menu-demo"
            menu-trigger="click"
            text-color="#333"
            style="height: 65px; border: 0"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1" class="header-title">首页</el-menu-item>
            <el-menu-item index="1-1" class="header-title">京和会员</el-menu-item>
            <el-menu-item index="1-2" class="header-title">守护会员</el-menu-item>
            <el-menu-item index="1-3" class="header-title">星空会员</el-menu-item>
            <el-menu-item index="2" class="header-title">联系我们</el-menu-item>
          </el-menu>
          <!-- 手机端菜单导航 v-bind:style="{backgroundColor:'#409EFF'}" -->
          <el-menu
            v-if="!seen"
            :default-active="activeIndex"
            class="el-menu-demo"
            menu-trigger="click"
            text-color="#dea100"
            style="height: 65px; border: 0"
            mode="horizontal"
            background-color="#000"
            @select="handleSelectMobile"
          >
            <el-submenu index="1" class="header-title mobile_menu">
              <template slot="title">首页</template>
              <el-menu-item index="1" class="header-title" style="color:#dea100">首页</el-menu-item>
            <el-menu-item index="1-1" class="header-title">京和会员</el-menu-item>
            <el-menu-item index="1-2" class="header-title">守护会员</el-menu-item>
            <el-menu-item index="1-3" class="header-title">星空会员</el-menu-item>
            <el-menu-item index="2" class="header-title">联系我们</el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
        <div v-if="seen"></div>
      </div>
      <div class="banner" style="width:100%; text-align: center;">
          <p>德安黑金卡，致力于为高净值客户提供高端医疗服务</p>
          <p>高端健康管理会员服务</p>
          <p>健康无疆 传承无限</p>
      </div>
      <div class="main">
        <div class="main-text">
          <div class="product_pc" id="京和会员">
            <el-row>
              <el-col :xs="24" :sm="12" >
                <div class="product_pc_one">
                  <div class="product_mobile_title font_size_30" style="font-size: 40px; margin-bottom: 20px;" >
                    {{ manages[0].title[0] }}
                  </div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[0]}}</div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[1]}}</div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[2]}}</div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[3]}}</div>
                </div>
              </el-col>
              <el-col :xs="24" :sm="12">
                <img :src="manages[0].img[0]" class="product_pc_img" />
              </el-col>
            </el-row>
          </div>
          <div class="product_pc" id="守护会员">
            <el-row>
              <el-col :xs="24" :sm="12">
                <img :src="manages[0].img[0]" class="product_pc_img" />
              </el-col>
              <el-col :xs="24" :sm="12">
                <div class="product_pc_one">
                  <div class="product_mobile_title font_size_30" style="font-size: 40px; margin-bottom: 20px;">
                    {{ manages[0].title[1] }}
                  </div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[4]}}</div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[5]}}</div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[6]}}</div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[7]}}</div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[8]}}</div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[9]}}</div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="product_pc" id="星空会员">
            <el-row>
              <el-col :xs="24" :sm="12">
                <div class="product_pc_one">
                  <div class="product_mobile_title font_size_30" style="font-size: 40px; margin-bottom: 20px;">
                    {{ manages[0].title[2] }}
                  </div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[10]}}</div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[11]}}</div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[12]}}</div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[13]}}</div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[14]}}</div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[15]}}</div>
                  <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[16]}}</div>
                </div>
              </el-col>
              <el-col :xs="24" :sm="12">
                <img :src="manages[0].img[0]" class="product_pc_img" />
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="footer">
          <div style="width: 50%">
            <p>
              <img src="../assets/gord.png" alt="" style="width: 70px; height: 60px;" />
            </p>
            <p>北京德安健康管理有限公司</p>
            <p>电话:400-602-9998</p>
            <p>地址:北京市朝阳区望京顺北大街诚盈中心12G</p>
          </div>
          <div style="width: 50%">
            <p>京ICP备2021005988号-1</p>
            <p>统一社会信用代码:91110105MA0071EX7F</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "Layout",
    data() {
      return {
        activeIndex: "1",
        company: {
          qrcode: require("../assets/other/公众号二维码.jpg"),
        },
        scroll: "",
        timer: "",
        seen: true,
        currentMenu: "首页",
        manages: [
                    {
                        img: [require('../assets/gord.png')],
                        title: ["德安·京和会员",'德安·守护会员（邀请制）','德安·星空会员（邀请制）'],
                        content: ["个性化定制体检","融合首长式保健服务",'北京协和专家会诊','尊享高端就医服务','幸福','是一家人的其乐融融','陪伴','是最长情的告白','守候','是最永恒的承诺','有这样一群人','常常仰望星空','探求深邃而无尽的未来','拥有强健的体魄','是踏上征程的力量源泉','用一生去追求','自信、自在、自由']
                    }
                ],
      };
    },
    methods: {
      handleSelect(key) {
        switch (key) {
          case "1":
            this.$router.push({ name: "Index" });
            break;
          case "1-1":
            window.location.hash = "#京和会员";
            break;
          case "1-2":
          window.location.hash = "#守护会员";
  
            break;
          case "1-3":
          window.location.hash = "#星空会员";
  
            break;
          case "2":
            // this.$router.push({ name: "Index" });
            // window.location.hash = "#产品特点";
            break;
        }
      },
      handleSelectMobile(key) {
        switch (key) {
          case "1":
            this.$router.push({ name: "Index" });
            break;
          case "1-1":
            window.location.hash = "#京和会员";
            break;
          case "1-2":
          window.location.hash = "#守护会员";
  
            break;
          case "1-3":
          window.location.hash = "#星空会员";
  
            break;
          case "2":
            // this.$router.push({ name: "Index" });
            // window.location.hash = "#产品特点";
            break;
        }
      },
      handleScroll() {
        this.scroll =
          document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
        if (this.scroll > 600) {
          //判断滚动后高度超过400px,就显示
          this.$refs.btn.style.display = "block";
        } else {
          this.$refs.btn.style.display = "none";
        }
      },
      backTop() {
        //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
        this.timer = setInterval(() => {
          let osTop =
            document.documentElement.scrollTop || document.body.scrollTop;
          let ispeed = Math.floor(-osTop / 5);
          document.documentElement.scrollTop = document.body.scrollTop =
            osTop + ispeed;
          if (osTop === 0) {
            clearInterval(this.timer);
          }
        }, 30);
      },
      login() {
        window.open("https://admin.fastice-tech.com/login");
      },
      register() {
        window.open("https://admin.fastice-tech.com/registe");
      },
      menuSeen() {
        // 屏幕尺寸
        let screenHeight = document.body.clientWidth;
        if (screenHeight > 600) {
          this.seen = true;
        } else {
          this.seen = false;
        }
      },
      logoClick() {
        this.$router.push({ name: "Index" });
        this.currentMenu = "首页";
      },
    },
    mounted() {
      this.menuSeen();
      // window.addEventListener("scroll", this.handleScroll);
    },
  };
  </script>
  <style scoped>
  .header {
    width: 100%;
    padding: 10px 0;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    z-index: 10000;
    top: 0;
    background: black;
    /* border: 1px solid red; */
    /* box-shadow: 1px 1px 5px 1px #ccc; */
    color: orange !important;
  }
  @media screen and (max-device-width: 600px) {
    .logo_img {
      float: left;
      width: auto;
      cursor: pointer;
    }
    #back_to_top {
      position: fixed;
      bottom: 50px;
      right: 5px;
      cursor: pointer;
    }
    #menu_index {
      float: right;
      width: auto;
      height: 100%;
    }
    .mobile_menu {
    }
    .el-menu--horizontal::v-deep .el-menu{
      background: transparent !important;
      
    }
    .el-menu--horizontal::v-deep .el-menu-item{
      background: transparent !important;
    }
    .el-menu--horizontal>.el-submenu.is-active::v-deep .el-submenu__title{
      color: orange !important;
      border-bottom: 1px solid orange;
      background: transparent !important;
    }
    ::v-deep .el-menu--popup{
      background: transparent !important;
    }
    .banner{
      height: 180px !important;
    }
    .banner p{
      margin: 0;
      color: orange;
      /* font-weight: bold; */
      font-size: 16px !important;
      line-height: 38px !important;
    }
    .product_pc .el-row{
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between !important;
      flex-direction: column;
    }
    .product_pc .el-row .el-col{
    /* border: 1px solid red; */
    width: 100% !important;
  }
  .font_size_30{
    font-size: 20px !important;
    margin-bottom: 0 !important;
  }
  .product_font_style{
    font-size: 12px !important;
    margin-top: 0 !important;
  }
  .product_pc_one{
    line-height: 30px !important;
  }
  .product_pc_img{
    height: 180px !important;
  }
  .product_pc{
    height: 450px !important;
  }
  .footer{
    flex-direction: column;
    align-items: flex-start !important;
  }
  .footer p{
    font-size: 14px;
  }
  }
  #header-img {
    width: 70px;
    height: 60px;
    /* float: left */
    /* margin: auto; */
    /* scale: .3; */
  }
  .header-logo {
    /* height: 50px; */
    width: 50px;
    align-self: center;
  }
  .banner p{
    margin: 0;
    color: orange;
    /* font-weight: bold; */
    font-size: 40px;
    line-height: 60px;
  }
  .header-important {
    float: left;
    margin-left: 6px;
    align-self: center;
  }
  .banner{
    border-top:5px solid orange;
    border-bottom:5px solid orange;
    margin-top: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height:300px;
  }
  .main {
    width: 100%;
    /* height: 600px; */
    background: black;
    /* margin-top: 20px; */
    position: absolute;
    /* border: 3px solid orange; */
  }
  .header::v-deep .el-menu{
    background: transparent !important;
  }
  .el-menu--horizontal>.el-menu-item{
    color: orange !important;
    font-size: 18px;
    background: transparent !important;
  }
  .el-menu--horizontal>.el-menu-item.is-active{
    color: orange;
    border-bottom: 2px solid rgb(239, 243, 9);
  }
  .el-menu--horizontal>.el-menu-item:hover{
    background: rgb(51, 51, 51);
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80vw;
    /* height: 300px; */
    margin: 0 auto;
    /* border: 1px solid red; */
    color: orange;
    margin-top: 20px;
  }
  .product_pc_one {
    /* text-align: center; */
    font-size: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    line-height: 40px;
  }
  .product_pc_img {
    width: 100%;
    height: 300px;
  }
  .product_font_style{
    font-size: 20px;
  }
  .product_pc{
    display: flex;
    width: 90%;
    height: 500px;
    margin: 20px auto;
    /* background: #f2f6fc; */
    align-items: center;
    color: orange;
    justify-content: center;
    /* border: 1px solid red; */
  }
  .product_pc .el-row{
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .product_pc .el-row .el-col{
    /* border: 1px solid red; */
    width: 30%;
  }
  </style>